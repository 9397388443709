* {
  padding: 0;
  margin: 0;
}
/* html,
body {
  width: 100vw;
} */
.paletteList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.colorList {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgb(173, 173, 173);
}
.paletteSmall {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
}
.palettePublic {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 40%;
}
.color {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.color:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.color:active::after {
  content: "Copied!";
}
.color:hover::after {
  /* content: "copy"; */
}
.userInfo {
  display: flex;
  flex-direction: column;
}
.userInfo h2,
.userInfo h3 {
  padding: 0;
  margin: 0;
}
.userInfo h3 {
  font-weight: 100;
  font-style: italic;
  text-indent: 20px;
}
.paletteInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paletteInfo span {
  display: flex;
  align-items: center;
  margin: 10px;
}
.paletteTitle {
  flex: 1;
}
@media only screen and (max-width: 600px) {
  .palettePublic {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 100%;
  }
}
